import React, { useState } from 'react';
import Layout from '@/layouts/quiz';
import { Mixpanel } from '@/utils';
import {
  Quiz01,
  Quiz02,
  Quiz03,
  Quiz04,
  Quiz05,
  Quiz06,
  Quiz07,
  Quiz08,
  Quiz09,
  Quiz10,
  Quiz11,
} from '@/actions';
import { QUIZ_TITLES } from '@/constants';

export default function Quiz() {
  React.useEffect(() => Mixpanel.track(`View Quiz Page`), []);
  const [pos, setPos] = useState(1);
  const [globalState, setGlobalState] = useState<any>({});

  const next = () => {
    if (pos !== 11 && pos >= 0) {
      setPos(pos + 1);
    }

    // if (pos === 11) {
    //   console.log(globalState);
    // }
  };

  const navigateScreens = () => {
    if (pos === 1) {
      if (typeof window !== `undefined`) {
        window.location.href = `/pricing`;
      }
      return;
    }
    setPos(pos - 1);
  };

  return (
    <Layout
      title="Quiz - Transforming your health through your gut"
      description="We aim to reduce over-dependence on pills by using good food to better the health
outcomes of people living with anxiety and depression."
      pos={pos}
      navigateScreens={navigateScreens}
    >
      <h5 className="mx-auto mb-[42px] w-fit">Question {pos} of 11</h5>
      {QUIZ_TITLES[`quiz-${pos}`] ? (
        <h2 className="mx-auto mb-[48px] w-fit max-w-[780px] md:text-[24px] text-center">
          {QUIZ_TITLES[`quiz-${pos}`]}
        </h2>
      ) : (
        <h2 className="mx-auto mb-[48px] w-fit max-w-[780px] md:text-[24px] text-center">
          To begin the quiz, please provide us your{` `}
          <span className="text-primary">personal Information</span> for
          customized plan.
        </h2>
      )}
      {pos === 1 && (
        <Quiz01
          globalState={globalState}
          setGlobalState={setGlobalState}
          next={next}
        />
      )}
      {pos === 2 && (
        <Quiz02
          globalState={globalState}
          setGlobalState={setGlobalState}
          next={next}
        />
      )}
      {pos === 3 && <Quiz03 setGlobalState={setGlobalState} next={next} />}
      {pos === 4 && <Quiz04 setGlobalState={setGlobalState} next={next} />}
      {pos === 5 && (
        <Quiz05
          globalState={globalState}
          setGlobalState={setGlobalState}
          next={next}
        />
      )}
      {pos === 6 && (
        <Quiz06
          globalState={globalState}
          setGlobalState={setGlobalState}
          next={next}
        />
      )}
      {pos === 7 && <Quiz07 setGlobalState={setGlobalState} next={next} />}
      {pos === 8 && <Quiz08 setGlobalState={setGlobalState} next={next} />}
      {pos === 9 && <Quiz09 setGlobalState={setGlobalState} next={next} />}
      {pos === 10 && <Quiz10 setGlobalState={setGlobalState} next={next} />}
      {pos === 11 && <Quiz11 setGlobalState={setGlobalState} />}
    </Layout>
  );
}
