import React, { ChangeEvent, useState } from 'react';
import zod from 'zod';
import { validateData } from '@/utils/validate';
import { showAlert } from '@/utils/alert';
import { Select } from '@/components/quiz/Select';
import { CANADA_PROVINCES, USA_STATES } from '@/data';

type Props = {
  globalState: any;
  setGlobalState: any;
  next: () => void;
};

export function Quiz05({ globalState, setGlobalState, next }: Props) {
  const [data, setData] = useState<any>({});
  const [states, setStates] = useState<{ [key: string]: string }[]>(USA_STATES);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [label, setLabel] = useState(`State`);

  const handleOnChange = (
    evt: ChangeEvent<HTMLSelectElement>,
    props: { label?: string; key: string },
  ) => {
    const { value } = evt.target;
    const payload = {
      [props.key]: {
        value,
        key: props.key,
        label: props.label,
      },
    };

    const schema = zod.object({
      [props.key]: zod.object({
        value: zod.string(),
        key: zod.string(),
        label: zod.string(),
      }),
    });

    const parsed = validateData(schema, payload);
    if (!parsed) {
      setIsValid(false);
      return;
    }

    setIsValid(true);

    const stateData = { state: undefined, province: undefined };

    if (props.key === `country` && value === `usa`) {
      delete stateData.province;
      setStates(USA_STATES);
    }

    if (props.key === `country` && value === `canada`) {
      delete stateData.state;
      setLabel(`Province`);
      setStates(CANADA_PROVINCES);
    }

    if (props.key === `country` && value === `Choose`) {
      setStates([]);
    }

    setData((state) => ({
      ...state,
      ...stateData,
      [props.key]: {
        value,
        key: props.key,
        label: props.label,
      },
    }));
  };

  function handleNext() {
    const { country, state: $state, province } = data;
    const validate = validateData(
      zod.object({
        country: zod.object({
          value: zod.string(),
          key: zod.string(),
          label: zod.string(),
        }),
        [label.toLowerCase()]: zod.object({
          value: zod.string(),
          key: zod.string(),
          label: zod.string(),
        }),
      }),
      { country, state: $state, province },
    );

    if (validate === null) {
      showAlert({
        type: `success`,
        text: `Please select your country and valid ${label.toLowerCase()} entry.`,
        position: `top`,
        stay: false,
      });
      return;
    }
    setGlobalState((state) => ({
      ...state,
      ...data,
    }));
    next();
  }

  return (
    <form onSubmit={handleNext} className="max-w-[600px] mx-auto">
      <div className="grid md:grid-cols-2 gap-[16px] md:gap-y-0">
        <Select
          label="Country"
          required
          value={globalState.country?.value}
          onChange={handleOnChange}
          hasLeadingOption
          options={[
            { text: `🇺🇸 United states of America`, value: `usa` },
            { text: `🇨🇦 Canada`, value: `canada` },
          ]}
        />
        <Select
          label={label}
          required
          value={globalState.state?.value}
          onChange={handleOnChange}
          hasLeadingOption
          options={states}
        />
      </div>

      <div className="mt-[62px] w-fit mx-auto">
        <button
          type="button"
          onClick={handleNext}
          disabled={!isValid}
          className={`font-medium rounded-[100px] text-[16px] px-[97px] py-[14.5px] bg-[#122279] text-white ${
            isValid ? `opacity-100` : `opacity-20`
          }`}
        >
          Next
        </button>
      </div>
    </form>
  );
}
