import React, { ChangeEvent, useState } from 'react';
import zod from 'zod';

import { Input } from '@/components/quiz/Input';
import { showAlert } from '@/utils/alert';
import { validateData } from '@/utils/validate';

type Props = {
  globalState: any;
  setGlobalState: any;
  next: () => void;
};

export function Quiz01({ globalState, setGlobalState, next }: Props) {
  const [data, setData] = useState<any>({});
  const [isValid, setIsValid] = useState<boolean>(false);

  const handleOnChange = (
    evt: ChangeEvent<HTMLInputElement>,
    props: { label?: string; key: string },
  ) => {
    const payload = {
      [props.key]: {
        value: evt.target.value,
        key: props.key,
        label: props.label,
      },
    };

    const schema = zod.object({
      [props.key]: zod.object({
        value:
          props.key === `first-name` ? zod.string() : zod.string().nullable(),
        key: zod.string(),
        label: zod.string(),
      }),
    });

    const parsed = validateData(schema, payload);
    if (!parsed) {
      setIsValid(false);
      return;
    }

    setIsValid(true);
    setData((state) => ({
      ...state,
      [props.key]: {
        value: evt.target.value,
        key: props.key,
        label: props.label,
      },
    }));
  };

  function handleNext() {
    if (!isValid) {
      showAlert({ text: `Please fill out required fields` });
      return;
    }
    setGlobalState((state) => ({
      ...state,
      ...data,
    }));
    next();
  }

  return (
    <div className="max-w-[600px] mx-auto">
      <div className="grid md:grid-cols-2 gap-[16px] md:gap-y-0">
        <Input
          label="First Name"
          placeholder="Enter first name"
          required
          value={globalState[`first-name`]?.value}
          onChange={handleOnChange}
        />
        <Input
          label="Last Name"
          placeholder="Enter last name"
          value={globalState[`last-name`]?.value}
          onChange={handleOnChange}
        />
      </div>

      <div className="mt-[62px] w-fit mx-auto">
        <button
          onClick={handleNext}
          type="button"
          disabled={!isValid}
          className={`font-medium rounded-[100px] text-[16px] px-[97px] py-[14.5px] bg-[#122279] ${
            isValid ? `opacity-100 text-white` : `opacity-20 text-white`
          }`}
        >
          Next
        </button>
      </div>
    </div>
  );
}
