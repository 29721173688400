import React, { ChangeEvent, useState } from 'react';
import zod from 'zod';
import { validateData } from '@/utils/validate';
import { showAlert } from '@/utils/alert';
import { Select } from '@/components/quiz/Select';

type Props = {
  setGlobalState: any;
  next: () => void;
};

export function Quiz03({ setGlobalState, next }: Props) {
  const [data, setData] = useState<any>({});
  const [isValid, setIsValid] = useState<boolean>(false);

  const handleOnChange = (
    evt: ChangeEvent<HTMLSelectElement>,
    props: { label?: string; key: string },
  ) => {
    const payload = {
      [props.key]: {
        value: evt.target.value,
        key: props.key,
        label: props.label,
      },
    };

    const schema = zod.object({
      [props.key]: zod.object({
        value: zod.string(),
        key: zod.string(),
        label: zod.string(),
      }),
    });

    const parsed = validateData(schema, payload);
    if (!parsed) {
      setIsValid(false);
      return;
    }

    setIsValid(true);
    setData((state) => ({
      ...state,
      [props.key]: {
        value: evt.target.value,
        key: props.key,
        label: props.label,
      },
    }));
  };

  function handleNext() {
    if (!isValid) {
      showAlert({ text: `Please select a valid option` });
      return;
    }
    setGlobalState((state) => ({
      ...state,
      ...data,
    }));
    next();
  }

  return (
    <form onSubmit={handleNext} className="max-w-[300px] mx-auto">
      <div className="grid">
        <Select
          label="Gender"
          required
          value={data.gender?.value}
          onChange={handleOnChange}
          options={[
            { text: `Choose`, value: null },
            { text: `Male`, value: `male` },
            { text: `Female`, value: `female` },
            { text: `Rather not say`, value: `rather-not-say` },
          ]}
        />
      </div>

      <div className="mt-[62px] w-fit mx-auto">
        <button
          type="button"
          onClick={handleNext}
          disabled={!isValid}
          className={`font-medium rounded-[100px] text-[16px] px-[97px] py-[14.5px] bg-[#122279] text-white ${
            isValid ? `opacity-100` : `opacity-20`
          }`}
        >
          Next
        </button>
      </div>
    </form>
  );
}
