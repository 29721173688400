import React, { FormEvent, useState } from 'react';
import zod from 'zod';
import { validateData } from '@/utils/validate';
import { showAlert } from '@/utils/alert';
import { Scale } from '@/components/quiz/Scale';

type Props = {
  // globalState: any;
  setGlobalState: any;
  next: () => void;
};

export function Quiz09({ setGlobalState, next }: Props) {
  const [data, setData] = useState<any>({});
  const [isSelected, setSelected] = useState<number>();

  const handleSelect = (
    evt: FormEvent<HTMLButtonElement>,
    props: { label?: string; key: string; option: any; answer: string },
  ) => {
    const { option, answer } = props;
    const value = { option, answer };
    const payload = {
      [props.key]: {
        value,
        key: props.key,
        label: props.label,
      },
    };

    const schema = zod.object({
      [props.key]: zod.object({
        value: zod.object({ option: zod.number(), answer: zod.string() }),
        key: zod.string(),
        label: zod.string(),
      }),
    });

    const parsed = validateData(schema, payload);
    if (!parsed) {
      return;
    }

    setSelected(option - 1);
    setData((state) => ({
      ...state,
      [props.key]: {
        value,
        key: props.key,
        label: props.label,
      },
    }));
  };

  function handleNext() {
    const validate = validateData(
      zod.object({
        'dietary-change': zod.object({
          value: zod.object({ option: zod.number(), answer: zod.string() }),
          key: zod.string(),
          label: zod.string(),
        }),
      }),
      { 'dietary-change': data[`dietary-change`] },
    );

    if (validate === null) {
      showAlert({
        type: `success`,
        text: `Please choose a valid option.`,
        position: `top`,
        stay: false,
      });
      return;
    }
    setGlobalState((state) => ({
      ...state,
      ...data,
    }));
    next();
  }

  return (
    <div onSubmit={handleNext} className="max-w-[500px] mx-auto">
      <div className="">
        <Scale
          label="Dietary Change"
          range={5}
          isSelected={isSelected}
          onChange={handleSelect}
          displayText={[
            `Not Willing`,
            `Fairly Willing`,
            `Willing`,
            `Very Willing`,
            `Strongly Willing`,
          ]}
        />
      </div>

      <div className="mt-[62px] w-fit mx-auto">
        <button
          type="button"
          onClick={handleNext}
          disabled={typeof isSelected !== `number`}
          className={`font-medium rounded-[100px] text-[16px] px-[97px] py-[14.5px] bg-[#122279] text-white ${
            typeof isSelected === `number` ? `opacity-100` : `opacity-20`
          }`}
        >
          Next
        </button>
      </div>
    </div>
  );
}
