import React, { ChangeEvent } from 'react';
import classNames from 'classnames';
import { BaseComponentProps } from '@/types';

type Props = {
  className?: string;
  labelClassName?: string;
  label: string;
  altLabel?: string;
  placeholder?: string;
  required?: boolean;
  type?: string;
  value?: string | undefined;
  defaultValue?: string | undefined;
  onChange: (
    evt: ChangeEvent<HTMLInputElement>,
    props: { label?: string; key: string },
  ) => void;
};

export function Input({
  className,
  label,
  altLabel,
  labelClassName,
  placeholder,
  required,
  type,
  value,
  defaultValue,
  onChange,
}: BaseComponentProps & Props) {
  const key = label?.toLowerCase().replaceAll(` `, `-`);
  const inputClassName = classNames(
    `border border-black rounded-[100px] h-[46px] text-black font-normal pl-[24px] text-[16px]`,
    className,
  );
  return (
    <label htmlFor={key} className="grid capitalize">
      <span
        className={classNames(
          `opacity-[0.5] mb-[8px] text-[14px]`,
          labelClassName,
        )}
      >
        {altLabel || label}
      </span>
      <input
        id={key}
        type={type}
        className={inputClassName}
        onChange={(evt) => onChange(evt, { label, key })}
        value={value}
        defaultValue={defaultValue}
        placeholder={placeholder}
        required={required}
      />
    </label>
  );
}

Input.defaultProps = {
  className: ``,
  labelClassName: ``,
  placeholder: `Fill out field`,
  type: `text`,
  required: false,
  value: undefined,
  defaultValue: undefined,
  altLabel: undefined,
};
