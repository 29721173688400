import React, { ChangeEvent, useState } from 'react';
import zod from 'zod';
import { Input } from '@/components/quiz/Input';
import { validateData } from '@/utils/validate';
import { showAlert } from '@/utils/alert';

type Props = {
  globalState: any;
  setGlobalState: any;
  next: () => void;
};

export function Quiz02({ globalState, setGlobalState, next }: Props) {
  const [data, setData] = useState<any>({});
  const [isValid, setIsValid] = useState<boolean>(false);

  const handleOnChange = (
    evt: ChangeEvent<HTMLInputElement>,
    props: { label?: string; key: string },
  ) => {
    const payload = {
      [props.key]: {
        value: evt.target.value,
        key: props.key,
        label: props.label,
      },
    };

    const schema = zod.object({
      [props.key]: zod.object({
        value: zod.string().email(),
        key: zod.string(),
        label: zod.string(),
      }),
    });

    const parsed = validateData(schema, payload);
    if (!parsed) {
      setIsValid(false);
      return;
    }

    setIsValid(true);
    setData((state) => ({
      ...state,
      [props.key]: {
        value: evt.target.value,
        key: props.key,
        label: props.label,
      },
    }));
  };

  function handleNext() {
    if (!isValid) {
      showAlert({ text: `Please enter a valid email address.` });
      return;
    }
    setGlobalState((state) => ({
      ...state,
      ...data,
    }));
    next();
  }

  return (
    <form onSubmit={handleNext} className="max-w-[300px] mx-auto">
      <div className="grid">
        <Input
          label="Email"
          placeholder="Enter email address"
          required
          type="email"
          defaultValue={globalState.email?.value}
          value={globalState.email?.value}
          onChange={handleOnChange}
        />
      </div>

      <div className="mt-[62px] w-fit mx-auto">
        <button
          type="button"
          onClick={handleNext}
          disabled={!isValid}
          className={`font-medium rounded-[100px] text-[16px] px-[97px] py-[14.5px] bg-[#122279] text-white ${
            isValid ? `opacity-100` : `opacity-20`
          }`}
        >
          Next
        </button>
      </div>
    </form>
  );
}
