import React, { ChangeEvent } from 'react';
import classNames from 'classnames';
import { BaseComponentProps } from '@/types';

type Props = {
  className?: string;
  labelClassName?: string;
  label: string;
  altLabel?: string;
  required?: boolean;
  value?: string | undefined;
  options: any[];
  hasLeadingOption?: boolean;
  onChange: (
    evt: ChangeEvent<HTMLSelectElement>,
    props: { label?: string; key: string },
  ) => void;
};

export function Select({
  className,
  label,
  altLabel,
  labelClassName,
  required,
  value,
  options,
  hasLeadingOption,
  onChange,
}: BaseComponentProps & Props) {
  const key = label?.toLowerCase().replaceAll(` `, `-`);
  const selectClassName = classNames(
    `border border-black rounded-[100px] h-[46px] text-black font-normal pl-[24px] text-[16px]`,
    className,
  );
  return (
    <label htmlFor={key} className="grid capitalize">
      <span
        className={classNames(
          `opacity-[0.5] mb-[8px] text-[14px]`,
          labelClassName,
        )}
      >
        {altLabel || label}
      </span>
      <select
        id={key}
        className={selectClassName}
        onChange={(evt) => onChange(evt, { label, key })}
        value={value}
        required={required}
      >
        {hasLeadingOption && <option value={null}>Choose</option>}
        {options.map((option: any) => (
          <option key={option.text} value={option.value}>
            {option.text}
          </option>
        ))}
      </select>
    </label>
  );
}

Select.defaultProps = {
  className: ``,
  labelClassName: ``,
  required: false,
  value: undefined,
  altLabel: undefined,
  hasLeadingOption: false,
};
