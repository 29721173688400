import * as notie from 'notie';

type Props = {
  type?: number | string; // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
  text: string;
  stay?: boolean; // optional, default = false
  time?: number; // optional, default = 3, minimum = 1,
  position?: string; // optional, default = 'top', enum: ['top', 'bottom']
  mode?: string;
};

export const showAlert = ({ mode = `alert`, ...props }: Props) =>
  notie[mode]?.(props);
