import React from 'react';

type Props = {
  pos: number;
  className?: string;
  navigateScreens: () => void;
};

export function QuizNavBar({ pos = 1, className, navigateScreens }: Props) {
  return (
    <nav className={`${className}`}>
      <button
        type="button"
        onClick={navigateScreens}
        className="flex gap-x-2 text-[14px]"
      >
        <svg
          className="h-[24px] w-[24px]"
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19 12.8149H5"
            stroke="#484352"
            strokeWidth="1.35"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 19.8149L5 12.8149L12 5.81494"
            stroke="#484352"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        {pos === 1 ? `Back` : `Previous Question`}
      </button>

      <a href="/" className="w-fit">
        <img
          src="/images/logo.svg"
          alt="Vitract | Using personalized nutrition to treat depression"
          className="w-[90px] md:w-fit ml-[30px] md:ml-[0px]"
        />
      </a>
    </nav>
  );
}

QuizNavBar.defaultProps = {
  className: ``,
};
