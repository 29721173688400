import React, { ChangeEvent, useState } from 'react';
import zod from 'zod';
import { validateData } from '@/utils/validate';
import { Select } from '@/components/quiz/Select';
import { Input } from '@/components/quiz/Input';

type Props = {
  globalState: any;
  setGlobalState: any;
  next: () => void;
};

export function Quiz06({ globalState, setGlobalState, next }: Props) {
  const [data, setData] = useState<any>({});
  const [isValid, setIsValid] = useState<boolean>(false);

  const handleOnChange = (
    evt: ChangeEvent<HTMLSelectElement | HTMLInputElement>,
    props: { label?: string; key: string },
  ) => {
    const { value } = evt.target;
    const payload = {
      [props.key]: {
        value,
        key: props.key,
        label: props.label,
      },
    };

    const schema = zod.object({
      [props.key]: zod.object({
        value: zod.string(),
        key: zod.string(),
        label: zod.string(),
      }),
    });

    const parsed = validateData(schema, payload);
    if (!parsed) {
      setIsValid(false);
      return;
    }

    setIsValid(true);

    setData((state) => ({
      ...state,
      [props.key]: {
        value,
        key: props.key,
        label: props.label,
      },
    }));
  };

  function handleNext() {
    // const validate = validateData();

    // if (validate === null) {
    //   showAlert({
    //     type: `success`,
    //     text: `Please select your country and valid ${label.toLowerCase()} entry.`,
    //     position: `top`,
    //     stay: false,
    //   });
    //   return;
    // }
    setGlobalState((state) => ({
      ...state,
      ...data,
    }));
    next();
  }

  return (
    <form onSubmit={handleNext} className="max-w-[650px] mx-auto">
      <div className="grid md:grid-cols-2 gap-[16px] md:gap-y-[49px]">
        <Input
          label="Mailing Address"
          required
          value={globalState[`mailing-address`]?.value}
          placeholder="Please enter your mailing address"
          onChange={handleOnChange}
        />
        <Input
          label="Zip Code"
          required
          value={globalState[`zip-code`]?.value}
          placeholder="Enter zip code"
          onChange={handleOnChange}
        />
        {/* <Select
          label="Zip Code"
          required
          value={globalState[`zip-code`]?.value}
          onChange={handleOnChange}
          options={[
            { text: `Yes`, value: `yes` },
            { text: `No`, value: `no` },
          ]}
        /> */}
        <Select
          label="Send Gut Test"
          altLabel="Can we send a gut test kit to this address?"
          required
          value={globalState[`zip-code`]?.value}
          onChange={handleOnChange}
          options={[
            { text: `Yes`, value: `yes` },
            { text: `No`, value: `no` },
          ]}
        />
        <Input
          label="Alt Mailing Address"
          altLabel="If no, please provide an address we can send to?"
          required
          value={globalState[`alt-mailing-address`]?.value}
          placeholder="Please provide an alternate address"
          onChange={handleOnChange}
        />
      </div>

      <div className="mt-[62px] w-fit mx-auto">
        <button
          type="button"
          onClick={handleNext}
          disabled={!isValid}
          className={`font-medium rounded-[100px] text-[16px] px-[97px] py-[14.5px] bg-[#122279] text-white ${
            isValid ? `opacity-100` : `opacity-20`
          }`}
        >
          Start the Quiz
        </button>
      </div>
    </form>
  );
}
