import React, { FormEvent } from 'react';
import classNames from 'classnames';
import { BaseComponentProps } from '@/types';

type Props = {
  className?: string;
  range: number;
  label: string;
  isSelected?: number;
  value?: string | undefined;
  displayText?: string[];
  onChange: (
    evt: FormEvent<HTMLButtonElement>,
    props: { label?: string; key: string; option: any; answer: string },
  ) => void;
};

export function Scale({
  className,
  label,
  isSelected,
  range,
  value,
  displayText,
  onChange,
}: BaseComponentProps & Props) {
  const key = label?.toLowerCase().replaceAll(` `, `-`);
  return (
    <>
      <div className="flex w-full ">
        {Array.from(Array(range).keys()).map((i: number, index: number) => (
          <button
            key={`${i}`}
            type="button"
            className={classNames(
              `gap-x-[14px] text-center h-[60px] flex items-center justify-center text-[16px] border-[#ECE8E8] w-full text-black font-medium`,
              index === 0 && `rounded-l-[40px]`,
              index + 1 === range && `rounded-r-[40px]`,
              ![index === 0].includes(true) &&
                `border-l-[0.8px] border-y-0 border-gray-600`,
              isSelected === i ? `bg-[#122279] text-white` : `bg-[#ECE8E8]`,
              className,
            )}
            onClick={(evt) =>
              onChange(evt, {
                label,
                answer: displayText[i],
                key,
                option: i + 1,
              })
            }
            value={value}
          >
            {i + 1}
          </button>
        ))}
      </div>
      {displayText[isSelected] && (
        <p className="mt-[24px] mx-auto w-fit">{displayText[isSelected]}</p>
      )}
    </>
  );
}

Scale.defaultProps = {
  className: ``,
  value: undefined,
  isSelected: undefined,
  displayText: [],
};
