import React from 'react';
import { BaseComponentProps } from '@/types';

type Props = {
  pos?: number;
  className?: string;
};

export function ProgressBar({ pos, className }: BaseComponentProps & Props) {
  const width = (pos / 11) * 100;
  return (
    <div className={`h-[4px] relative w-full ${className}`}>
      <div className="h-[4px] bg-[#EBEBEB] rounded-[20px] absolute w-full" />
      <div
        style={{ width: `${width}%` }}
        className="absolute rounded-[20px] bg-[#FF713F] h-[4px]"
      />
    </div>
  );
}

ProgressBar.defaultProps = {
  pos: 1,
  className: ``,
};
