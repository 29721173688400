export const USA_STATES = [
  `Alabama`,
  `Alaska`,
  `American Samoa`,
  `Arizona`,
  `Arkansas`,
  `California`,
  `Colorado`,
  `Connecticut`,
  `Delaware`,
  `District Of Columbia`,
  `Federated States Of Micronesia`,
  `Florida`,
  `Georgia`,
  `Guam`,
  `Hawaii`,
  `Idaho`,
  `Illinois`,
  `Indiana`,
  `Iowa`,
  `Kansas`,
  `Kentucky`,
  `Louisiana`,
  `Maine`,
  `Marshall Islands`,
  `Maryland`,
  `Massachusetts`,
  `Michigan`,
  `Minnesota`,
  `Mississippi`,
  `Missouri`,
  `Montana`,
  `Nebraska`,
  `Nevada`,
  `New Hampshire`,
  `New Jersey`,
  `New Mexico`,
  `New York`,
  `North Carolina`,
  `North Dakota`,
  `Northern Mariana Islands`,
  `Ohio`,
  `Oklahoma`,
  `Oregon`,
  `Palau`,
  `Pennsylvania`,
  `Puerto Rico`,
  `Rhode Island`,
  `South Carolina`,
  `South Dakota`,
  `Tennessee`,
  `Texas`,
  `Utah`,
  `Vermont`,
  `Virgin Islands`,
  `Virginia`,
  `Washington`,
  `West Virginia`,
  `Wisconsin`,
  `Wyoming`,
].map((state) => ({
  value: state.toLowerCase(),
  text: state,
}));

export const CANADA_PROVINCES = [
  `Alberta`,
  `British Columbia`,
  `Manitoba`,
  `New Brunswick`,
  `Newfoundland and Labrador`,
  `Northwest Territories`,
  `Nova Scotia`,
  `Nunavut`,
  `Ontario`,
  `Prince Edward Island`,
  `Quebec`,
  `Saskatchewan`,
  `Yukon Territory`,
].map((state) => ({
  value: state.toLowerCase(),
  text: state,
}));
