import React, { FormEvent, useState } from 'react';
import zod from 'zod';
import { validateData } from '@/utils/validate';
import { Option } from '@/components/quiz/Option';
import { showAlert } from '@/utils/alert';

type Props = {
  // globalState: any;
  setGlobalState: any;
  next: () => void;
};

const options = [
  { label: `Antibiotics`, option: `A`, text: `Yes` },
  { label: `Antibiotics`, option: `B`, text: `No` },
];

export function Quiz10({ setGlobalState, next }: Props) {
  const [data, setData] = useState<any>({});
  const [isSelected, setSelected] = useState<string>();
  const [scope, setScope] = useState(`button`);

  const handleSelect = (
    evt: FormEvent<HTMLButtonElement>,
    props: { label?: string; key: string; option: any; text: string },
  ) => {
    setScope(`button`);
    const { option, text } = props;
    const value = { option, text };
    const payload = {
      [props.key]: {
        value,
        key: props.key,
        label: props.label,
      },
    };

    const schema = zod.object({
      [props.key]: zod.object({
        value: zod.object({ option: zod.string(), text: zod.string() }),
        key: zod.string(),
        label: zod.string(),
      }),
    });

    const parsed = validateData(schema, payload);
    if (!parsed) {
      return;
    }

    setSelected(option);
    setData((state) => ({
      ...state,
      [props.key]: {
        value,
        key: props.key,
        label: props.label,
      },
    }));
  };

  function handleNext() {
    const { antibiotics } = data;
    const validate = validateData(
      zod.object({
        antibiotics: zod.object({
          value: zod.object({ option: zod.string(), text: zod.string() }),
          key: zod.string(),
          label: zod.string(),
        }),
      }),
      { antibiotics },
    );

    if (validate === null) {
      showAlert({
        type: `success`,
        text: `Please choose a valid option.`,
        position: `top`,
        stay: false,
      });
      return;
    }
    setGlobalState((state) => ({
      ...state,
      ...data,
    }));
    next();
  }

  return (
    <form onSubmit={handleNext} className="max-w-[335px] mx-auto">
      <div className="grid gap-y-[28px]">
        {options.map((option) => (
          <Option
            key={option.option}
            label={option.label || option.text}
            text={option.text}
            option={option.option}
            onChange={handleSelect}
            isSelected={
              [isSelected].includes(option.option) && scope === `button`
            }
          />
        ))}
      </div>

      <div className="mt-[62px] w-fit mx-auto">
        <button
          type="button"
          onClick={handleNext}
          disabled={!isSelected}
          className={`font-medium rounded-[100px] text-[16px] px-[97px] py-[14.5px] bg-[#122279] text-white ${
            isSelected ? `opacity-100` : `opacity-20`
          }`}
        >
          Next
        </button>
      </div>
    </form>
  );
}
