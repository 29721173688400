import React, { ReactChild } from 'react';
import { Helmet } from 'react-helmet';
import Footer from '@/components/Footer';
import { QuizNavBar } from '@/components/quiz/NavBar';
import { ProgressBar } from '@/components/quiz/ProgressBar';

type Props = {
  children: ReactChild[];
  pos: number;
  navigateScreens: any;
  description: string;
  title: string;
};

export default function QuizLayout(props: Props) {
  const { children, description, title, pos, navigateScreens } = props;
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href="https://vitract.com" />
        <script id="mcjs">
          {`!function(c,h,i,m,p){m=c.createElement(h),p=c.getElementsByTagName(h)[0],m.async=1,m.src=i,p.parentNode.insertBefore(m,p)}(document,"script","https://chimpstatic.com/mcjs-connected/js/users/b3a74adf59f0c96f88daec5c3/a2d99679e4c859ee5a0b18939.js");`}
        </script>
      </Helmet>

      <main className="min-h-screen h-full">
        <div className="max-w-[86%] pt-[36px] mx-auto">
          <ProgressBar pos={pos} className="mb-[34px]" />
          <QuizNavBar
            pos={pos}
            className="flex justify-between"
            navigateScreens={navigateScreens}
          />
          <div className="mt-[93px] max-w-[780px] mx-auto mb-[100px]">
            {children}
          </div>
        </div>
        <Footer />
      </main>
    </>
  );
}
