import React, { FormEvent } from 'react';
import classNames from 'classnames';
import { BaseComponentProps } from '@/types';

type Props = {
  className?: string;
  labelClassName?: string;
  label: string;
  option?: string;
  text: string;
  value?: string | undefined;
  isSelected?: boolean;
  onChange: (
    evt: FormEvent<HTMLButtonElement>,
    props: { label?: string; key: string; option: any; text: string },
  ) => void;
};

export function Option({
  className,
  label,
  option,
  labelClassName,
  text,
  value,
  isSelected,
  onChange,
}: BaseComponentProps & Props) {
  const key = label?.toLowerCase().replaceAll(` `, `-`);
  const selectClassName = classNames(
    `gap-x-[14px] border border-black rounded-[100px] h-[60px] font-normal flex items-center pl-[8px] text-[16px]`,
    isSelected ? `bg-[#122279] border-[#122279] text-white` : `text-black`,
    className,
  );
  return (
    <button
      id={key}
      type="button"
      className={selectClassName}
      onClick={(evt) => onChange(evt, { label, key, option, text })}
      value={value}
    >
      <label htmlFor={key} className="uppercase">
        <span
          className={classNames(
            `h-[44px] w-[44px] font-[500] px-[16px] py-[10px] bg-[#EEEEEE] rounded-[300px]`,
            labelClassName,
            isSelected ? `text-[#122279]` : `text-black`,
          )}
        >
          {option}
        </span>
      </label>
      <span>{text}</span>
    </button>
  );
}

Option.defaultProps = {
  className: ``,
  labelClassName: ``,
  value: undefined,
  option: undefined,
  isSelected: false,
};
